import React, {
  useState,
  useEffect
} from "react";
import {
  useAuth0,
  withAuthenticationRequired
} from "@auth0/auth0-react";
import Loading from "../components/Loading";
import AddJob from "../components/addJob";
import { Container, Row, Col } from "reactstrap";
import {
  Form
} from 'react-bootstrap';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Rating } from 'primereact/rating';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'

export const JobsComponent = () => {
  const {
    user
  } = useAuth0();
  const [userFull, setUser] = useState({
    "_id": "",
    "name": ""
  });
  const [expandedRows, setExpandedRows] = useState(null);
  const [jobs, setJobs] = useState();
  const [liens, setLiens] = useState();
  const [contractors, setContractors] = useState();

  const [newSettings, setnewSettings] = useState({
    state: false,
    job: {},
    id: ''
  });
  // const [editJob, setEditJob] = useState({});
  // const [editJobState, setEditJobState] = useState(false);
  console.log(user)

  const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        number: { value: null, matchMode: FilterMatchMode.CONTAINS },
        location: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // jobNumber: { value: null, matchMode: FilterMatchMode.EQUALS },
        // invoice: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        // status: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        console.log()

        _filters['global'].value = value;
        console.log(_filters)
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

  const editJobChange = async (index) => {
  }

  const deleteJob = async (index) => {
  }

  const handleDataUpdate = async (email) => {
    console.log(email)
     const user = await fetch(process.env.REACT_APP_NEXT_PUBLIC_SERVER_URL + "/user/"+ email, {
       method: "GET",
       // mode:'no-cors',
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
    setUser(user)

    // console.log(this.props.user,)
    var jobs = await fetch(process.env.REACT_APP_NEXT_PUBLIC_SERVER_URL + "/jobs-pm/"+ user._id, {
       method: "GET",
       // mode:'no-cors',
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
    console.log(jobs)
    var currentLiens = await fetch(process.env.REACT_APP_NEXT_PUBLIC_SERVER_URL + "/liens/project_manager/"+ user._id, {
       method: "GET",
       // mode:'no-cors',
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())

    var contractors = await fetch(process.env.REACT_APP_NEXT_PUBLIC_SERVER_URL + "/all/contractors", {
       method: "GET",
       // mode:'no-cors',
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((contractors) => contractors.json())
      // console.log(contractors)
      // setContractors(jobs)
      var alphaContractors = contractors.sort((a, b) => a.email.localeCompare(b.email))
      setContractors(alphaContractors);
    // console.log(currentLiens)
      // console.log(user)
      var contractors_list = contractors;
      // console.log(contractors_list)
      // currentForm.project_manager_id = user._id;
      // currentForm.project_manager_name = user.name;
      // console.log(jobs)
      // setJobs(jobs)
      jobs.forEach(function(job, i) {
        const job_index = i;
        job.total = 0;
        job.trtd = 0;
        job.contractors.map((contractor, x) => {
          // console.log(contractor)
          const contractor_index = x;
          if (currentLiens.length > 0){
          const response_liens = currentLiens.filter(lien => {
            // console.log(lien)
            // console.log(contractor)
            // console.log(job)
            return lien.contractor_id === contractor.id && lien.jobNumber === job.number;
          });

          console.log(response_liens)
          const data = response_liens[0];
          // console.log(jobs[job_index].contractors[contractor_index])
          const contractor_info = contractors_list.filter(contractor_item => {
            // console.log(contractor_item)
            return contractor_item._id === contractor.id;
          });
          // console.log(contractor_info)
          if (response_liens.length > 0){
            var job_contractor_total = 0;
            if (contractor_info.length > 0) {
              jobs[job_index].contractors[contractor_index].company = contractor_info[0].company;
            }
            response_liens.forEach(function(lien, z) {
              if (lien.status === "approved" || lien.status === "completed") {
                 console.log(lien)
                  if (lien.lineItems_manHours_total) {} else {
                    lien.lineItems_manHours_total = 0
                  }
                  if (lien.lineItemsTotal) {} else {
                    lien.lineItemsTotal = 0
                  }
                  if (lien.lineItems_other_total) {} else {
                    lien.lineItems_other_total = 0
                  }
                  var job_total = ((Number(lien.lineItems_manHours_total) + Number(lien.lineItemsTotal)+ Number(lien.lineItems_other_total)) - ((Number(lien.lineItems_manHours_total) + Number(lien.lineItemsTotal) + Number(lien.lineItems_other_total)) * (jobs[job_index].contractors[contractor_index].retention/100)));
                  console.log(job_total)
                  job_contractor_total += job_total;
                  // console.log(job_contractor_total)
              }
             
            })

            jobs[job_index].contractors[contractor_index].job_total = "$" + ((Number(data.lineItems_manHours_total) + Number(data.lineItemsTotal)+ Number(data.lineItems_other_total)) - ((Number(data.lineItems_manHours_total) + Number(data.lineItemsTotal)+ Number(data.lineItems_other_total)) * (jobs[job_index].contractors[contractor_index].retention/100))).toFixed(2).toString()
            jobs[job_index].contractors[contractor_index].status = data.status.toUpperCase();
            jobs[job_index].contractors[contractor_index].grand_total = job_contractor_total.toFixed(2).toString();
            jobs[job_index].contractors[contractor_index].total_retention = (job_contractor_total * (data.retention/100)).toFixed(2).toString();
            job.total += job_contractor_total;
            job.trtd += (job_contractor_total * (data.retention/100));
            if (x === job.contractors.length -1) {
              if (i === jobs.length -1) {
                console.log(jobs)
                setJobs(jobs)
                setUser(user)
              }
            }
          } else {
            var job_contractor_total = 0;
            if (contractor_info.length > 0) {
              jobs[job_index].contractors[contractor_index].company = contractor_info[0].company;
            }
            
            jobs[job_index].contractors[contractor_index].job_total = "No Total Yet";
            jobs[job_index].contractors[contractor_index].status = "UNSUBMITTED";
            jobs[job_index].contractors[contractor_index].grand_total = 0.00;
            jobs[job_index].contractors[contractor_index].total_retention = 0.00;
            job.total += 0;
            job.trtd += 0;
            if (x === job.contractors.length -1) {
              if (i === jobs.length -1) {
                console.log(jobs)
                setJobs(jobs)
                setUser(user)
              }
            }
          }
        }
        })

      })
  }

  const editProjectManager = (pm) => {
        setnewSettings({
          state: true,
          job: pm,
          id: pm._id
        })
        console.log(pm)
    }

  const expandAll = () => {
        let _expandedRows = {};

        jobs.forEach((p) => (_expandedRows[`${p._id}`] = true));

        setExpandedRows(_expandedRows);
    };

    const collapseAll = () => {
        setExpandedRows(null);
    };

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };

    const totalBodyTemplate = (rowData) => {
        console.log(rowData)
        return formatCurrency(rowData.total);
    };
    const totalBodyTemplate2 = (rowData) => {
        console.log(rowData)
        if (rowData.budget) {
          return "$"+ formatCurrency(rowData.budget);
        } else {
          return formatCurrency(0);
        }
        
    };

    const statusOrderBodyTemplate = (rowData) => {
    };

    const percentBodyTemplate = (rowData) => {
      return rowData.retention + "%";
    };


    const totalRetentionBodyTemplate = (rowData) => {
      return formatCurrency(rowData.trtd);
    };

    const totalPaymentsBodyTemplate = (rowData) => {
      return formatCurrency(Number(rowData.grand_total));
    };

    const retentionBodyTemplate = (rowData) => {
      console.log(rowData)
      return formatCurrency(Number(rowData.total_retention));
    };

    const allowExpansion = (rowData) => {
        console.log(rowData)
        return rowData.contractors.length > 0;
    };

    const rowExpansionTemplate = (data) => {
        return (
            <div className="p-3">
                <DataTable value={data.contractors}>
                    <Column field="company"  ></Column>
                    <Column field="retention"   body={percentBodyTemplate}></Column>
                    <Column field="grand_total" header="Total Payments"  body={totalPaymentsBodyTemplate}></Column>
                    <Column field="total_retention" header="Total Retention"  body={retentionBodyTemplate}></Column>
                </DataTable>
            </div>
        );
    };

    var modalOpenTemplate = (pm) => {
   
           return (
              <div className="button_flex">
                <Button icon="pi pi-file-edit" severity="warning" raised aria-label="Options" onClick={(e) => editProjectManager(pm)} />
              {/* <Button icon="pi pi-trash" raised severity="danger" aria-label="Options" onClick={(e) => deleteProjectManager(pm)} /> */}
             </div>
           );
  };


    const onRowExpand = (event) => {
    };

    const onRowCollapse = (event) => {
    };

    const renderHeader = () => {
        return (
          <div>
            <div className="flex justify-space-between">
                <h2 className="text-xl text-900 font-bold">Your Jobs:</h2>
                <div className="flex flex-wrap justify-content-end gap-2">
                <Button icon="pi pi-plus" label="Expand All" onClick={expandAll} text />
                <Button icon="pi pi-minus" label="Collapse All" onClick={collapseAll} text />
            </div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
            
            </div>
        );
    };
  const header = renderHeader();

  


  useEffect(() => {
    let isMounted = true;

    handleDataUpdate(user.email).then((data) => {
      if (isMounted) {
      }
    });

  }, []);

  return ( <>

    <AddJob user={user} newSettings={newSettings}/>


    <DataTable value={jobs} header={header} filters={filters} sortMode="multiple" expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} onRowExpand={onRowExpand} onRowCollapse={onRowCollapse} rowExpansionTemplate={rowExpansionTemplate} dataKey="_id" header={header} tableStyle={{ minWidth: '60rem' }} globalFilterFields={['name', 'number', 'location']}>
      <Column expander={allowExpansion} style={{ width: '5rem' }} />
      <Column field="number" header="Job Number" sortable />
      <Column field="name" header="Name" sortable />
      <Column field="location" header="Location" sortable />
      <Column field="total" header="Total" sortable body={totalBodyTemplate} />
      <Column field="budget" header="Budget" sortable body={totalBodyTemplate2} />
      <Column field="trtd" header="Total Retention To Date" sortable body={totalRetentionBodyTemplate}/>
      <Column body={modalOpenTemplate}></Column>
    </DataTable>
    </>
  );
};

export default withAuthenticationRequired(JobsComponent, {
  onRedirecting: () => < Loading / > ,
});
