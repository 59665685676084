import React, {
  useState,
  useEffect
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Chart } from 'primereact/chart';
import AnimatedNumber from "animated-number-react";

import 'primeicons/primeicons.css';  
import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/primereact.min.css";
import logo from "../assets/logo.png";
import { Skeleton } from 'primereact/skeleton';
import CurrencyFormat from 'react-currency-format';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Ripple } from 'primereact/ripple';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';

const Hero = () => {
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const [userFull, setUser] = useState({
    "_id": "",
    "name": ""
  });

  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [liens, setLiens] = useState([]);
  const [projectManagers, setPms] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [jobs, setJobs] = useState([]);

  const [thisMonth, setThisMonth] = useState(0);
  const [thisYear, setThisYear] = useState(0);
  const [thisTotal, setThisTotal] = useState(0);

     const [filters, setFilters] = useState({
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
          contractor: { value: null, matchMode: FilterMatchMode.EQUALS },
          value: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          jobNumber: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          invoice: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          status: { value: null, matchMode: FilterMatchMode.EQUALS },
      });
      const [loading, setLoading] = useState(true);
      const [globalFilterValue, setGlobalFilterValue] = useState('');

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        // console.log()

        _filters['global'].value = value;
        // console.log(_filters)
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

        const getSeverity = (status) => {
        console.log(status)
        var trueStatus = status;
        if (trueStatus !=undefined) {
          trueStatus = status.toLowerCase()
          switch (trueStatus) {

            case 'started':
                return 'primary';

            case 'unsubmitted':
                return 'primary';

            case 'signed':
                return 'primary';

            case 'in review':
                return 'info';

            case 'rejected':
                return 'danger';

            case 'approved':
                return 'success';

            case 'completed':
                return 'success';
                
            default:
                return null;
        }
        } else {
          switch (trueStatus) {

            case 'started':
                return 'primary';

            case 'unsubmitted':
                return 'primary';

            case 'signed':
                return 'primary';

            case 'in review':
                return 'info';

            case 'rejected':
                return 'danger';

            case 'approved':
                return 'success';

            case 'completed':
                return 'success';
                
            default:
                return null;
        }
        }
        
        
    };

  const statusBodyTemplate = (job) => {
    return <Tag value={job.status} severity={getSeverity(job.status)}></Tag>;
  };


    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    label: 'First Dataset',
                    data: [65, 59, 80, 81, 56, 55, 40],
                    fill: false,
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    tension: 0.4
                },
                {
                    label: 'Second Dataset',
                    data: [28, 48, 40, 19, 86, 27, 90],
                    fill: false,
                    borderColor: documentStyle.getPropertyValue('--pink-500'),
                    tension: 0.4
                }
            ]
        };


        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, []);

  // console.log(user)

  const handleDataUpdate = async (email) => {
    console.log(email)
    console.log(process.env.NEXT_PUBLIC_SERVER_URL)
      var response = await fetch(process.env.REACT_APP_NEXT_PUBLIC_SERVER_URL + "/user/"+ email, {
        // mode:'no-cors',
       method: "GET",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
      console.log(response)
      return response
  }

  const handleJobsUpdate = async (pm_id) => {

    console.log(user._id)
    var response = await fetch(process.env.REACT_APP_NEXT_PUBLIC_SERVER_URL + "/jobs-pm/"+ pm_id, {
       method: "GET",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
    var currentLiens = await fetch(process.env.REACT_APP_NEXT_PUBLIC_SERVER_URL + "/pm-liens-approved/"+ pm_id, {
       method: "GET",
       // mode:'no-cors',
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
    
      var contractors_list = contractors;

      var jobs_filtered = response;
      console.log(currentLiens)
      jobs_filtered.forEach(function(job, i) {
        const job_index = i;
        job.total = 0;
        job.trtd = 0;
        job.contractors.forEach(function(contractor, x) {
          // console.log(contractor)
          // console.log(liens.length)
          const contractor_index = x;
          if (currentLiens.length > 0) {
            const response_liens = currentLiens.filter(lien => {
              // console.log(lien)
              // console.log(contractor.id)
              // console.log(job.number)
              return lien.contractor_id === contractor.id && lien.jobNumber === job.number;
            });
            var data = response_liens[0]
            console.log(response_liens)
            // console.log(jobs[job_index].contractors[contractor_index])
            const contractor_info = contractors_list.filter(contractor_item => {
              // console.log(contractor_item)
              return contractor_item._id === contractor.id;
            });
            // console.log(contractor_info)
            if (response_liens.length > 0) {
              var job_contractor_total = 0;
              if (contractor_info.length > 0) {
                response[job_index].contractors[contractor_index].company = contractor_info[0].company;
              }
              response_liens.forEach(function(lien, z) {
                if (lien.status === "approved" || lien.status === "completed") {
                   // console.log(lien)
                    if (lien.lineItems_manHours_total) {} else {
                      lien.lineItems_manHours_total = 0
                    }
                    if (lien.lineItemsTotal) {} else {
                      lien.lineItemsTotal = 0
                    }
                    if (lien.lineItems_other_total) {} else {
                      lien.lineItems_other_total = 0
                    }
                    var job_total = ((Number(lien.lineItems_manHours_total) + Number(lien.lineItemsTotal)+ Number(lien.lineItems_other_total)) - ((Number(lien.lineItems_manHours_total) + Number(lien.lineItemsTotal) + Number(lien.lineItems_other_total)) * (jobs_filtered[job_index].contractors[contractor_index].retention/100)));
                    // console.log(job_total)
                    job_contractor_total += job_total;
                    // console.log(job_contractor_total)
                }
               
              })

              jobs_filtered[job_index].contractors[contractor_index].job_total = "$" + ((Number(data.lineItems_manHours_total) + Number(data.lineItemsTotal)+ Number(data.lineItems_other_total)) - ((Number(data.lineItems_manHours_total) + Number(data.lineItemsTotal)+ Number(data.lineItems_other_total)) * (jobs_filtered[job_index].contractors[contractor_index].retention/100))).toFixed(2).toString()
              jobs_filtered[job_index].contractors[contractor_index].status = data.status.toUpperCase();
              jobs_filtered[job_index].contractors[contractor_index].grand_total = job_contractor_total.toFixed(2).toString();
              jobs_filtered[job_index].contractors[contractor_index].total_retention = (job_contractor_total * (data.retention/100)).toFixed(2).toString();
              job.total += job_contractor_total;
              job.trtd += (job_contractor_total * (data.retention/100));
              if (x === job.contractors.length -1) {
                console.log(i )
                console.log(jobs_filtered.length -1 )
                if (i === jobs_filtered.length -1) {
                  console.log(jobs_filtered)
                  setJobs(jobs_filtered)
                  setUser(user)
                  return jobs_filtered
                }
              }
            } else {
              var job_contractor_total = 0;
              if (contractor_info.length > 0) {
                jobs_filtered[job_index].contractors[contractor_index].company = contractor_info[0].company;
              }
              
              jobs_filtered[job_index].contractors[contractor_index].job_total = "No Total Yet";
              jobs_filtered[job_index].contractors[contractor_index].status = "UNSUBMITTED";
              jobs_filtered[job_index].contractors[contractor_index].grand_total = 0.00;
              jobs_filtered[job_index].contractors[contractor_index].total_retention = 0.00;
              job.total += 0;
              job.trtd += 0;
              if (x === job.contractors.length -1) {
                 console.log(i )
                console.log(jobs_filtered.length -1 )
                if (i === jobs_filtered.length -1) {
                  console.log(jobs_filtered)
                  setJobs(jobs_filtered)
                  setUser(user)
                  return jobs_filtered
                }
              }
            }
          }
        })
        if (i === jobs_filtered.length -1) {
          setTimeout(function() {
                  console.log(jobs_filtered)
                  setJobs(jobs_filtered)
                  setUser(user)
                  return jobs_filtered
          },500)
                  
        }

      })
      
    
  }

  const handleContractorsUpdate = async (id) => {
    // console.log(id)
      var liens = await fetch(process.env.REACT_APP_NEXT_PUBLIC_SERVER_URL + "/pm-liens-approved/"+ id, {
        // mode:'no-cors',
       method: "GET",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((liens) => liens.json())
      // console.log(liens)
      setLiens(liens.reverse())
       var contractors_filtered = [];
       var month = 0;
       var year = 0;
       var alltime = 0;
      liens.forEach(function(lien, i) {
        // console.log(lien)
        // console.log(i)

        var now = new Date();
        var check = new Date(lien.dateFormat);
        // console.log(check.getFullYear())
        // console.log(now.getFullYear())
        if (
           (check.getFullYear() == now.getFullYear()) &&
           (check.getMonth() == now.getMonth())
        ) {
          // console.log(lien.value)
          // var currentMonthTotal = thisMonth;
        month = Number(month) + Number(lien.value);
          // setThisMonth(currentMonthTotal.toFixed(2))
           // remanining days in current month and today. Use > if you don't need today.
        }

        if (
           (check.getFullYear() == now.getFullYear()) 
        ) {
          // console.log(lien.value)
          // console.log(thisYear)
          // var currentYearTotal = Number(thisYear);
        year = Number(year) + Number(lien.value);
          // console.log(currentYearTotal)
          // setThisYear(currentYearTotal.toFixed(2))
           // remanining days in current month and today. Use > if you don't need today.
        }
          // console.log(alltime)
          // console.log(lien.value)
          alltime = Number(alltime) + Number(lien.value);
          // console.log(alltime)
          // setThisTotal(currentTotal.toFixed(2))

        var contractor_object = {
          contractor: lien.contractor,
          contractorCompany: lien.contractorCompany,
          contractor_id: lien.contractor_id,
          avg: 0,
          all: 0,
          liens: []
        }
        var check = contractors_filtered.map(e => e.contractor_id).indexOf(lien.contractor_id);
        // console.log(check)
        // console.log(lien.lineItems_manHours_total)
        // console.log(lien.lineItemsTotal)
        // console.log(lien.lineItems_other_total)
        var value_plain = (Number(lien.lineItems_manHours_total) + Number(lien.lineItemsTotal) + Number(lien.lineItems_other_total)) - ((Number(lien.lineItems_manHours_total) + Number(lien.lineItemsTotal) + Number(lien.lineItems_other_total)) * (lien.retention/100));
        if (check === -1) {
          
          contractor_object.liens.push(value_plain)
          const average = array => array.reduce((a, b) => a + b) / array.length;
          const total = array => array.reduce((a, b) => a + b, 0);
          var avg = average(contractor_object.liens)
          let contractor_total = 0;
          // iterate over each item in the array
          for (let i = 0; i < contractor_object.liens.length; i++ ) {
            contractor_total += contractor_object.liens[i];
          }
          // console.log(contractor_total)
          contractor_object.avg = Number(avg);
          contractor_object.contractor_total = Number(contractor_total);
          // console.log(contractor_object.liens)
          contractor_object.count = contractor_object.liens.length;
          contractors_filtered.push(contractor_object)

        } else {
          var contractor_filtered_object = contractors_filtered.find(e => e.contractor_id === lien.contractor_id);
          contractor_filtered_object.liens.push(value_plain)
          const average = array => array.reduce((a, b) => a + b) / array.length;
          let contractor_total = 0;
          // iterate over each item in the array
          for (let i = 0; i < contractor_filtered_object.liens.length; i++ ) {
            contractor_total += contractor_filtered_object.liens[i];
          }
          // console.log(contractor_total)
          var avg = average(contractor_filtered_object.liens)
          contractor_filtered_object.avg = Number(avg);
          contractor_filtered_object.contractor_total = Number(contractor_total);
          contractor_filtered_object.count = contractor_filtered_object.liens.length;
          // console.log(contractor_filtered_object.contractor)
          // console.log(contractor_filtered_object.liens)
        }
        if (i === liens.length -1) {
          // console.log(contractors_filtered)
          setThisMonth(month.toFixed(2))
          setThisYear(year.toFixed(2))
          setThisTotal(alltime.toFixed(2))
          setContractors(contractors_filtered)
        }
      })
      return liens
  }

  const formatDate = (value) => {
        // console.log(value)
        var date = new Date(value);
        // console.log(date)
        var newValue = date.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
        // console.log(newValue)
        return newValue;
    }

    const formatCurrency = (value) => {
        // console.log(value)
        if (value != null) {
          return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        } else {
          value = 0;
          return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        }
        
    }

const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.dateFormat);
    }

    const balanceBodyTemplate = (rowData) => {
        
        return formatCurrency(rowData.avg);
    }

        const balanceBodyTemplate2 = (rowData) => {
        
        return formatCurrency(rowData.contractor_total);
    }

     const balanceBodyTemplate3 = (rowData) => {
        
        return formatCurrency(rowData.total);
    }

        const balanceBodyTemplate4 = (rowData) => {
        
        return formatCurrency(rowData.trtd);
    }

     const balanceBodyTemplate6 = (rowData) => {
        // console.log(rowData)
        if (rowData.budget) {
          return "$"+ formatCurrency(rowData.budget);
        } else {
          return formatCurrency(0);
        }
        
    };

     const balanceBodyTemplate5 = (rowData) => {
        // console.log(rowData)
        if (rowData.budget) {
          return ((rowData.total / rowData.budget) * 100) + "%";
        } else {
          return "0%";
        }
        
    };



  const renderHeader = () => {
        return (
            <div className="flex justify-space-between">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };
  const header = renderHeader();
  const footer = `In total there are ${liens ? liens.length : 0} payments.`;

  const formatValue = (value) => {
    // console.log(value)
    return value.toFixed(2);
  }
  

   useEffect(() => {
    let isMounted = true;
    // console.log(userFull._id)
    if (isAuthenticated) {
      handleDataUpdate(user.email).then((data) => {
        setUser(data)
        handleContractorsUpdate(data._id).then((data2) => {
          setLiens(data2.reverse())
          handleJobsUpdate(data._id).then((jobs_data) => {
            console.log(jobs_data)
            // setJobs(jobs_data)
          })
        })

    });
    }
    
    return () => {
      // isMounted = false;
    };
    // }
  }, []);
if (isAuthenticated) {
  return (
    <div className="dashboard">
      <div className="card_stats_view">
        <Card title="Approved this Month">

          <CurrencyFormat value={thisMonth} displayType={'text'} thousandSeparator={true} prefix={'$'} />

          <Skeleton className={liens ? 'p-skeleton p-component' : 'p-skeleton p-component active'} width="100%" height="6rem" borderRadius="16px"></Skeleton>
        </Card>

        <Card title="Approved - Year to Date">

        <CurrencyFormat value={thisYear} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          <Skeleton className={liens ? 'p-skeleton p-component' : 'p-skeleton p-component active'} width="100%" height="6rem" borderRadius="16px"></Skeleton>
        </Card>

        <Card title="Approved - Total">

          <CurrencyFormat value={thisTotal} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          <Skeleton className={liens ? 'p-skeleton p-component' : 'p-skeleton p-component active'} width="100%" height="6rem" borderRadius="16px"></Skeleton>
        </Card>

        
      </div>
      <Card title="Jobs: ">
          <div className="card chart">
              <DataTable value={jobs} tableStyle={{ minWidth: '50rem' }}>
                <Column field="number" header="Job Number"></Column>
                <Column field="name" header="Name"></Column>
                <Column field="location" header="Location"></Column>
                <Column field="total" header="Total" body={balanceBodyTemplate3}></Column>
                <Column field="percent" header="Percent of Budget Used" body={balanceBodyTemplate6}></Column>
                <Column field="budget" header="Budget" body={balanceBodyTemplate5}></Column>
                <Column field="trtd" header="Total Retention" body={balanceBodyTemplate4}></Column>
            </DataTable>
            </div>
            </Card>
      
      <Card title="Contractors: ">
          <div className="card chart">
              <DataTable value={contractors} sortOrder={-1} showGridlines stripedRows tableStyle={{ minWidth: '50rem' }} emptyMessage="No Payments found.">
                <Column field="contractor" sortable header="Contractor"></Column>
                <Column field="contractorCompany" sortable header="Company"></Column>
                <Column field="avg" sortable header="Average" body={balanceBodyTemplate}></Column>
                <Column field="contractor_total" sortable header="Total" body={balanceBodyTemplate2}></Column>
                <Column field="count" sortable header="Count"></Column>
              </DataTable>
              <Skeleton className={liens ? 'p-skeleton p-component' : 'p-skeleton p-component active'} height=".5rem"></Skeleton>
          </div>
      </Card>
    </div>
  )
} else {
  return (
  <div className="dashboard">
      <div className="text-center hero my-5">
        <img className="mb-3 app-logo" src={logo} alt="React logo" />
        <h1 className="mb-4">PM Dashboard</h1>

        <p className="lead">

        </p>
      </div>
  </div>
  )
}
};

export default Hero;
